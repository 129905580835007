import React from 'react'
import './AboutUsBio.scss'
import AboutBio from '../../../models/AboutBio'


interface IAboutUsPageProps {
    children?: any,
    bio: AboutBio
}

function AboutUsBio(props: IAboutUsPageProps) {

    const [readMore, setReadMore] = React.useState(false);

    return (
        <div className='bio-container'>
            <div className='bio-wrap'>
                <div className='bio-image'>
                    <img src={props.bio.image} />
                </div>
                <div className='bio-content'>
                    <h1 className='bio-name'>{props.bio.name}</h1>
                    <h2>{props.bio.tagline}</h2>
                        {props.bio.bioStart}
                    <div className='readmore-container' style={{
                       height: readMore ? 'auto' : '0px',
                    }}>
                        {props.bio.bioRest}
                    </div>
                    {props.bio.bioRest != undefined && <div className='readmore-button'><span onClick={() => setReadMore(!readMore)}>{readMore ? 'Read Less' : 'Read More'}</span></div>}
                </div>
            </div>
        </div>
    )
}

export default AboutUsBio