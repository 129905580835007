import React from 'react'

interface HomeTemplateProps {
    children: any
}

function HomeTemplate(props: HomeTemplateProps) {
  return props.children;
}

export default HomeTemplate