import React from 'react'
import HeroTextsProps from '../HeroTextsProps'

function TxtSnapToFight() {
  return (
<>
      <div className='large-descriptor'>FIGHTING BUTT ROD BUILDING COMPONENT</div>
                <div className='main-description'>
                    <div className='text row first'>
                        <div className='never-lose-it'>NEVER LOSE IT</div>
                        <div className='when-you-need-it'>WHEN YOU NEED IT USE IT</div>
                    </div>
                    <div className='text second'>
                        <div className='snap-to-fight'>
                            <em>SNAP TO </em>
                            <span>FIGHT</span>
                        </div>
                        <div className='snap-to-store'>
                            <em>SNAP TO </em>
                            <span>STORE</span>
                        </div>
                    </div>
                    <div className='text third'>
                        <div className='so-much-more'>HIDDEN T GIVES YOU SO MUCH MORE</div>
                    </div>
                </div>
 </>
  )
}

export default TxtSnapToFight