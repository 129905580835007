import React from 'react'
import './StoreNotReady.scss';
import MobileHeader from '../organisms/header/MobileHeader';
import WhiteBodyTemplate from '../templates/whitebody-template/WhiteBodyTemplate';
import Header from '../organisms/header/Header';
import Footer from '../organisms/footer/Footer';

function StoreNotReady() {
    return <WhiteBodyTemplate>
    <Header currentPage={'about-us'} isHome={false} />
    <MobileHeader currentPage='about-us' isHome={false} />
{/* <PageBanner background='images/about-banner.jpg' className='about-banner-wrap'><h1>Our <b>Story</b></h1></PageBanner> */}
  

   <div className='store-not-ready-content-wrap'>
        <div className='made-in-america'>
            <img src='/flag.jpg' />
            <span>Made In America</span>
            <img src='/flag.jpg' />
        </div>
        <div className='big-buy-now'>
                BUY NOW
        </div>
     
   </div>
   
   <Footer />
</WhiteBodyTemplate>
}

export default StoreNotReady