import React, { useEffect, useRef } from 'react'
import './HomeHeroRight.scss'
import TxtSnapToFight from '../../molecules/Hero3D/HeroTexts/slide2/TxtSnapToFight'
import TxtLargeLogo from '../../molecules/Hero3D/HeroTexts/slide1/TxtLargeLogo';
import { AnimatePresence, motion } from 'framer-motion';
import TxtThreePicture from '../../molecules/Hero3D/HeroTexts/slide3/TxtThreePicture';

interface TextSlideProps {
    duration: number;
    delay: number;
    slide: any;
    animationDetails: TextSlideAnimationProps;
}
interface TextSlideAnimationProps {
    slideNumber: number;
}

let slideSettings = {
    duration: 5
}

let slideArr : TextSlideProps[] = [
    {
        duration: 2,
        delay: slideSettings.duration,
        slide: <TxtLargeLogo />,
        animationDetails: {
            slideNumber: 0
        }
    },
    {
        duration: slideSettings.duration,
        delay: slideSettings.duration,
        slide: <TxtSnapToFight />,
        animationDetails: {
            slideNumber: 1
        }
    },
    {
        duration: slideSettings.duration,
        delay: slideSettings.duration,
        slide: <TxtThreePicture />,
        animationDetails: {
            slideNumber: 2
        }
    }
]

function HomeHeroRight() {

    const [debugMode, setDebugMode] = React.useState(false)
    const [currentSlide, setCurrentSlide] = React.useState(1)
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const triggerNextSlide = () => {
        setCurrentSlide((prevSlide) =>
          prevSlide < slideArr.length - 1 ? prevSlide + 1 : 0
        );
      };
    

    const slideVariants = {
        hidden: { opacity: 0, x: 0 },
        visible: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 0 },
      }

      useEffect(() => {
        if(!debugMode) {
            timerRef.current = setTimeout(triggerNextSlide, slideArr[currentSlide].duration * 1000);
        }
        
        return () => {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
        }
      }, [currentSlide]);

  return (
    <>
   
   <AnimatePresence>
      {slideArr.map((item, index) =>
        currentSlide === index ? (
          <motion.div
            key={index}
            variants={slideVariants}
            initial="hidden"
            animate="visible"
            className='slide-indi-container'
            id={`txt-slide-${index}`}
            exit="exit"
            transition={{ 
                enter: { duration: 0.5, delay: 0 },
                exit: { duration: 0.5, delay: slideArr[currentSlide].duration - 0.5 } 
              }}
            onAnimationStart={(definition) => {
              if (definition === "exit") {
                if (timerRef.current) {
                  clearTimeout(timerRef.current);
                }
              }
            }}
          >
            {item.slide}
          </motion.div>
        ) : null
      )}
    </AnimatePresence>
                    
    </>
  )
}

export default HomeHeroRight