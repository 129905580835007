import React from 'react'
import '../Slides.scss';
function TxtThreePicture() {
  return (
    <>
 <div className='main-description' id='slide3'>
                   
                   <div className='three-col'>
                    <div className='colpic leftpic'>
                      {/* <div className='img-container roRight'>
                          <img src='/images/franco.jpg' alt='3d-1' />
                        </div> */}
                    </div>
                    <div className='coltext'>
                        <h1>Expensive Rubber cushion soiled or lost? Belt stowed away? Not a problem with HIDDEN T.</h1>
                    </div>
                    <div className='colpic'>
                    <div className='img-container  roLeft'>
                        <img src='/images/using-hiddenT.jpg'/>
                        </div>
                    </div>
                   </div>
                   
                </div>
    </>
  )
}

export default TxtThreePicture