import React from 'react'
import './WhiteBodyTemplate.scss';

function WhiteBodyTemplate(props: any) {
  return (
    <div className='white-body-template'>
        {props.children}
    </div>
  )
}

export default WhiteBodyTemplate