import React from 'react'
import './HomeHeroLeft.scss'
import Hero3D from '../../molecules/Hero3D/Hero3D'

function HomeHeroLeft() {
  return (
    <>
      <div className='logo-wrap'>
                    <div className='welcome'>Welcome to</div>
                    <div className='logo'>
                        <img src='/images/logo.png' alt='logo' />
                    </div>
                </div>

                <div className='underlogo-block'>
                    <div className='product-3d'>
                        <div className='model-wrapper'>
                        <Hero3D />
                        </div>
                    </div>
                    <div className='tagline'>
                        <div>THE ULTIMATE ROD COMPONENT</div>
                        <div>A MUST HAVE - BUILT TO LAST</div>
                    </div>

                </div>
    </>
  )
}

export default HomeHeroLeft