import React, { Suspense, useEffect, useReducer, useRef, useState } from 'react'
import './Hero3D.scss'
import AnimationSlide from '../../../models/AnimationSlide';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, TransformControls, Html, Loader } from '@react-three/drei';
import { extend, useThree, useFrame } from '@react-three/fiber'
import { useSpring } from '@react-spring/three';
import Hero3DModel from './Hero3DModel';
import Hero3DAnimationArray from './Hero3DAnimationArray';
import {Vector3} from 'three';
extend({ TransformControls, OrbitControls })

type State = {
  slides: AnimationSlide[];
  currentSlide: AnimationSlide;
};

type Action = {
  type: 'next';
};

const initialState: State = {
  slides: Hero3DAnimationArray,
  currentSlide: Hero3DAnimationArray[0],
};


function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'next': {
      const currentIndex = state.slides.findIndex(slide => slide.id === state.currentSlide.id);
      const nextIndex = (currentIndex + 1) % state.slides.length;  // loop back to start
      return { ...state, currentSlide: state.slides[nextIndex] };
    }
    default:
      throw new Error();
  }
}

function Hero3D() {

    const [state, dispatch] = useReducer(reducer, initialState);

    const [tempRotationX, setTempRotationX] = React.useState(state.currentSlide.modelData.rotation.x);
    const [tempRotationY, setTempRotationY] = React.useState(state.currentSlide.modelData.rotation.y);
    const [tempRotationZ, setTempRotationZ] = React.useState(state.currentSlide.modelData.rotation.z);
  
    const [tempPositionX, setTempPositionX] = React.useState(state.currentSlide.modelData.position.x);
    const [tempPositionY, setTempPositionY] = React.useState(state.currentSlide.modelData.position.y);
    const [tempPositionZ, setTempPositionZ] = React.useState(state.currentSlide.modelData.position.z);
  
    const [tempScale, setTempScale] = React.useState(state.currentSlide.modelData.scale);
  
    const [object, setObject] = useState<THREE.Group>();
  
    const [showControls, setShowControls] = useState(false);
  
    const modelRef = useRef<THREE.Group>(null);
  
    const { rotationSpring, positionSpring, scaleSpring } = useSpring({
      rotationSpring: [state.currentSlide.modelData.rotation.x, state.currentSlide.modelData.rotation.y, state.currentSlide.modelData.rotation.z].map((r: number) => (r * Math.PI) / 180),
      positionSpring: [state.currentSlide.modelData.position.x, state.currentSlide.modelData.position.y, state.currentSlide.modelData.position.z],
      config: { mass: 1, tension: 280, friction: 120, duration: state.currentSlide.duration },
      scaleSpring: tempScale,
    });
  
    // use this to trigger the next slide on setInterval
    useEffect(() => {
      const interval = setInterval(() => {
        dispatch({ type: 'next' });
      }, state.currentSlide.duration + state.currentSlide.delayAfter);
      return () => clearInterval(interval);
    }, [state.currentSlide]);


    useEffect(() => {
    //   setTempRotationX(currentSlide.modelData.rotation.x);
    //   setTempRotationY(currentSlide.modelData.rotation.y);
    //   setTempRotationZ(currentSlide.modelData.rotation.z);
      
    }, [state.currentSlide.id]);
  
    const [transformMode, setTransformMode] = useState<"translate" | "rotate" | "scale">("translate");

    const controlsRef = useRef<any>();

    useEffect(() => {
        if (controlsRef.current) {
          const controls = controlsRef.current;
          
          const onChangeEnd = () => {
            // At this point, modelRef.current.position, rotation, and scale
            // should be up-to-date with the transformations made by the controls.
            console.log("Position: ", modelRef.current!.position);
            console.log("Rotation: ", modelRef.current!.rotation);
            console.log("Scale: ", modelRef.current!.scale);
          };
      
          // The 'change' event is dispatched on every frame while the controls are transforming the object.
          // The 'objectChange' event is dispatched every time the object's transformation is updated by the controls.
          // 'change' and 'objectChange' are dispatched too frequently, and are generally not suitable for storing transformation data.
          // The 'dragging-changed' event is dispatched when the user starts or stops transforming the object.
          // But it does not include the final transformation. That's why we use 'dragging-changed' for the final transformation.
          controls.addEventListener('dragging-changed', onChangeEnd);
          
          return () => {
            controls.removeEventListener('dragging-changed', onChangeEnd);
          };
        }
      }, [modelRef, controlsRef]);

  return (<>
    <div className='debugger'>
        <button onClick={() => setTransformMode("translate")}>Translate</button>
        <button onClick={() => setTransformMode("rotate")}>Rotate</button>
        <button onClick={() => setTransformMode("scale")}>Scale</button>
        <button onClick={() => setShowControls(!showControls)}>Hide Controls</button>
        <div className='debugger__item'>
        Position: {tempPositionX.toFixed(2)}, {tempPositionY.toFixed(2)}, {tempPositionZ.toFixed(2)}
        |  Rotation: {tempRotationX.toFixed(2)}, {tempRotationY.toFixed(2)}, {tempRotationZ.toFixed(2)}
        </div>
      </div>

        <Canvas camera={{ position: [0, 0, 10] }}>
            <ambientLight intensity={0.8} />
            <directionalLight position={[10, 10, 0]} intensity={0.5} />
            <directionalLight position={[-10, -10, 0]} intensity={0.5} />

            <Suspense fallback={
                <Html
                    center><Loader /></Html>
            }>

         
<TransformControls
ref={controlsRef}
object={modelRef.current!}
            enabled={!!object}
            mode={transformMode}
            showX={true && showControls}
            showY={true && showControls}
            showZ={true && showControls}
            size={0.5}
            
            onPointerMove={() => {
             
            }}
            onPointerUp={() => {
                if (modelRef.current) {
               
                    let posX = modelRef.current.position.x;
                    let posY = modelRef.current.position.y;
                    let posZ = modelRef.current.position.z;
                    setTempPositionX(posX);
                    setTempPositionY(posY);
                    setTempPositionZ(posZ);

                    let rotX = modelRef.current.rotation.x;
                    let rotY = modelRef.current.rotation.y;
                    let rotZ = modelRef.current.rotation.z;
                    setTempRotationX(rotX);
                    setTempRotationY(rotY);
                    setTempRotationZ(rotZ);
                    console.log("POSITIONS", {});

                  }
            }}
          >
          <Hero3DModel
              modelRef={modelRef}
              tempRotation={{ x: tempRotationX, y: tempRotationY, z: tempRotationZ }}
              tempPosition={{ x: tempPositionX, y: tempPositionY, z: tempPositionZ }}
              tempScale={tempScale}
              animation={state.currentSlide}
              setObject={setObject}
              rotationSpring={rotationSpring}
              positionSpring={positionSpring}
              scaleSpring={scaleSpring}
            />

              </TransformControls>

            </Suspense>
            
       
        </Canvas>
        </> )
}

export default Hero3D