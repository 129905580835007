import React from 'react'
import './HomeHero.scss';
import HomeHeroLeft from '../home-hero-left/HomeHeroLeft';
import HomeHeroRight from '../home-hero-right/HomeHeroRight';

function HomeHero() {
  return (
    <div className='home-hero'>
        <div className='video-container'>

            <video muted={true} autoPlay={true} playsInline={true} loop={true}>
  <source src="/videos/video1.mp4" type="video/mp4" />
</video>
        </div>
        <div className='overlay'></div>
        <div className='transparent-overlay'></div>

        <div className='hero-content'>
            <div className='hero-left'>

              
              <HomeHeroLeft />

              

            </div>

            {/* ################ */}
            <div className='hero-right'>

                {<HomeHeroRight /> }
            </div>
        </div>
        <div className='scroll-down-wrap'>
                <img src='/images/scroll-down.png' alt='scroll down' />
              </div>
        <div className='hero-wave'></div>
    </div>
  )
}

export default HomeHero