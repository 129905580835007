import AnimationSlide, { CustomVector2, CustomVector3 } from '../../../models/AnimationSlide'

const Hero3DAnimationArray : AnimationSlide[] = [
    {
        id: 0,
        cssClass: "slide-0",
        duration: 1000,
        delayAfter: 0,
        textData: {
            content: () => <h1>Slide 0</h1>,
            position: {
                x: 0,
                y: 0,
            }
        },
        modelData: {
            rotation: {
                x: 0,
                y: 0,
                z: 0
            },
            position: {
                x: 0,
                y: -5,
                z: 0
            },
            scale: 110,
            hasAnimation: false
        }
    },
    {
        id: 1,
        cssClass: "slide-1",
        duration: 4000,
        delayAfter: 3000,
        animationDuration: 6000,
        textData: {
            content: () => <h1>Slide 0</h1>,
            position: {
                x: 0,
                y: 0
            }
        },
        modelData: {
            rotation: {
                x: 180,
                y: 0,
                z: 10
            },
            position: {
                x: 1,
                y: 5,
                z: 0
            },
            scale: 100,
            hasAnimation: true
        }
    },
    {
        id: 2,
        cssClass: "slide-1",
        duration: 4000,
        delayAfter: 3000,
        textData: {
            content: () => <h1>Slide 0</h1>,
            position: {
                x: 0,
                y: 0
            }
        },
        modelData: {
            rotation: {
                x: 0,
                y: 0,
                z: -10
            },
            position: {
                x: 1,
                y: -5,
                z: 0
            },
            scale: 120,
            hasAnimation: false
        }
    },
    {
        id: 3,
        cssClass: "slide-1",
        duration: 4000,
        delayAfter: 3000,
        textData: {
            content: () => <h1>Slide 0</h1>,
            position: {
                x: 0,
                y: 0
            }
        },
        modelData: {
            rotation: {
                x: 180,
                y: 180,
                z: -10
            },
            position: {
                x: 2,
                y: 5,
                z: 0
            },
            scale: 120,
            hasAnimation: true
        }
    },
    {
        id: 4,
        cssClass: "slide-0",
        duration: 3000,
        delayAfter: 0,
        textData: {
            content: () => <h1>Slide 0</h1>,
            position: {
                x: 0,
                y: 0,
            }
        },
        modelData: {
            rotation: {
                x: 0,
                y: 0,
                z: 0
            },
            position: {
                x: 0,
                y: -5,
                z: 0
            },
            scale: 110,
            hasAnimation: false
        }
    },
    

   
];



// const Hero3DAnimationArray : AnimationSlide[] = [
//     {
//         id: 0,
//         cssClass: "slide-0",
//         duration: 500,
//         delayAfter: 0,
//         textData: {
//             content: () => <h1>Slide 0</h1>,
//             position: {
//                 x: 0,
//                 y: 0
//             }
//         },
//         modelData: {
//             rotation: {
//                 x: 150,
//                 y: -20,
//                 z: 0
//             },
//             position: {
//                 x: 0,
//                 y: 0,
//                 z: 0
//             },
//             scale: 150,
//             hasAnimation: false
//         }
//     },
//     {
//         id: 1,
//         cssClass: "slide-0",
//         duration: 1000,
//         delayAfter: 1000,
//         textData: {
//             content: () => <h1>Slide 0</h1>,
//             position: {
//                 x: 0,
//                 y: 0
//             }
//         },
//         modelData: {
//             rotation: {
//                 x: 0,
//                 y: -90,
//                 z: 0
//             },
//             position: {
//                 x: 0,
//                 y: -5,
//                 z: 0
//             },
//             scale: 150,
//             hasAnimation: false
//         }
//     },
//     {
//         id: 2,
//         cssClass: "slide-1",
//         duration: 1000,
//         delayAfter: 3000,
//         textData: {
//             content: () => <h1>Slide 0</h1>,
//             position: {
//                 x: 0,
//                 y: 0
//             }
//         },
//         modelData: {
//             rotation: {
//                 x: 0,
//                 y: -720,
//                 z: 0
//             },
//             position: {
//                 x: 0,
//                 y: -5,
//                 z: 0
//             },
//             scale: 150,
//             hasAnimation: true
//         }
//     },
//     {
//         id:3,
//         cssClass: "slide-2",
//         duration: 1000,
//         delayAfter: 0,
//         textData: {
//             content: () => <h1>Slide 0</h1>,
//             position: {
//                 x: 0,
//                 y: 0
//             }
//         },
//         modelData: {
//             rotation: {
//                 x: 180,
//                 y: -720,
//                 z: 0
//             },
//             position: {
//                 x: 0,
//                 y: 5,
//                 z: 0
//             },
//             scale: 150,
//             hasAnimation: false
//         }
//     },
//     {
//         id:4,
//         cssClass: "slide-2",
//         duration: 1000,
//         delayAfter: 3000,
//         textData: {
//             content: () => <h1>Slide 0</h1>,
//             position: {
//                 x: 0,
//                 y: 0
//             }
//         },
//         modelData: {
//             rotation: {
//                 x: 180,
//                 y: -720,
//                 z: 0
//             },
//             position: {
//                 x: 0,
//                 y: 5,
//                 z: 0
//             },
//             scale: 150,
//             hasAnimation: true
//         }
//     }
// ];


export default Hero3DAnimationArray;