import React, { createRef, useState } from 'react'
import './ContactUsPage.scss'
import Header from '../organisms/header/Header'
import WhiteBodyTemplate from '../templates/whitebody-template/WhiteBodyTemplate'
import PageBanner from '../molecules/page-banner/PageBanner'
import Footer from '../organisms/footer/Footer'
import MobileHeader from '../organisms/header/MobileHeader'

function ContactUsPage() {

  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const firstNameRef = createRef<HTMLInputElement>();
  const lastNameRef = createRef<HTMLInputElement>();
  const emailRef = createRef<HTMLInputElement>();
  const phoneRef = createRef<HTMLInputElement>();
  const messageRef = createRef<HTMLTextAreaElement>();

  const [sending, setSending] = useState<boolean>(false);

  const validateForm = () => {
    let hasError = false;
    let errorMessage = '';
    if(firstname === '') {
      hasError = true;
      errorMessage = 'Please enter your first name.';
      firstNameRef.current!.focus();
    } else if(lastname === '') {
      hasError = true;
      errorMessage = 'Please enter your last name.';
      lastNameRef.current!.focus();
    } else if(email === '' && phone === '') {
      hasError = true;
      errorMessage = 'Please enter either an email or phone number.';
      emailRef.current!.focus();
    } else if(message === '') {
      hasError = true;
      errorMessage = 'Please enter your message.';
      messageRef.current!.focus();
    }

    setHasError(hasError);
    setErrorMessage(errorMessage);
    return !hasError;
  }



  const handleFormSubmit = async () => {

    let apiURLS = {
      local: 'https://127.0.0.1/hiddent/favoriteCMS/api/contact.php',
      live: 'https://favoritecreative.com/hiddent/favoriteCMS/api/contact.php'
    }
  
    let apiURL = apiURLS.live;


    let isFormValid = validateForm();

    // get data ready to send as a post for the form
    let data = {
      full_name: firstname + ' ' + lastname,
      email_address: email,
      phone: phone,
      comments: message
    }

    console.log(JSON.stringify(data));

    if(isFormValid) { 
      setSending(true);
      await fetch(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if(data.success)
        {
          setFirstname('');
          setLastname('');
          setEmail('');
          setPhone('');
          setMessage('');
          alert('Your message has been sent. Thank you!');
          setSending(false);
        }
        else{
          alert('There was an error sending your message. Please try again later.');
        }
      })
      .catch((e) => {
        console.log('There was an error sending your message. Please try again later.' +  e);
      }
      );
    }
  }

  return <WhiteBodyTemplate>
  <Header currentPage={'contact-us'} isHome={false} />
  <MobileHeader currentPage='contact-us' isHome={false} />
<PageBanner background='images/contact-banner.jpg' className='contact-banner-wrap'><h1>CONTACT <b>US</b></h1></PageBanner>


 <div className='contact-content-wrap'>

    {/* <div className='contact-info-wrap'>
      <div className='text'>
        <h1>Once you use the Hiddent T, you'll never go back.</h1>
        <p>To contact us, please use the form below and we will get back to you soon!</p>
        </div>
    </div> */}

    <div className='contact-forms-wrap'>
      <div className='primary-contact-form'>
        <h1>Contact Form</h1>
        <div className='errors-wrap'>
          {hasError && <div className='error-message'>{errorMessage}</div>}
        </div>
        <div className='contact-form'>

          <div className='form-row'>
            <div className='form-cell'>
              <label htmlFor='firstname'>First Name</label>
              <input type='text' name='firstname' id='firstname' onChange={(e) => setFirstname(e.target.value)} value={firstname} ref={firstNameRef} />
            </div>

            <div className='form-cell'>
              <label htmlFor='lastname'>Last Name</label>
              <input type='text' name='lastname' id='lastname' onChange={(e) => setLastname(e.target.value)} value={lastname} ref={lastNameRef} />
            </div>
          </div>

          <div className='form-row'>
            <div className='form-cell'>
              <label htmlFor='email'>Email</label>
              <input type='email' name='email' id='email' onChange={(e) => setEmail(e.target.value)} value={email} ref={emailRef} />
            </div>

            <div className='form-cell'>
              <label htmlFor='phone'>Phone</label>
              <input type='tel' name='phone' id='phone' onChange={(e) => setPhone(e.target.value)} value={phone} ref={phoneRef} />
            </div>
          </div>

          <div className='form-row'>
            <div className='form-cell fullcell'>
              <label htmlFor='message'>Comments/Questions</label>
              <textarea onChange={(e) => setMessage(e.target.value)} name='message' value={message} ref={messageRef} />
            </div>
          </div>

          <div className='form-row'>
            <div className='submit'>
              <button disabled={sending} onClick={handleFormSubmit}>Submit</button>
            </div>
          </div>
        </div>

      </div>
      <div className='gallery-form-wrap'>

        <div className='additional-info'>
          <h1>Rod Builders, Retailers, Wholesalers and Distributors contact us directly</h1>
        </div>

        <div className='send-pics'>
          <h1>Send us your HIDDENT T pictures!</h1>
          <p>Highlight your HIDDEN T experience by uploading it to our gallery!</p>
          <p><a href='mailto:info@hiddentproducts.com?subject=Hidden T Picture'>info@hiddentproducts.com</a></p>
        </div>
        <div className='gallery-image'>
          <img src='images/camera.jpg' />
        </div>

        <div className='quick-contact-list'>
          <div className='quick-contact'>
              <b>To contact Captn'Russ</b>
              <p><a href='mailto:russ@hiddentproducts.com'>russ@hiddentproducts.com</a></p>
          </div>
          <div className='quick-contact'>
              <b>To contact Greg Steiner</b>
              <p><a href='mailto:greg@hiddentproducts.com'>greg@hiddentproducts.com</a></p>
          </div>
          <div className='quick-contact'>
              <b>To contact Will Smith</b>
              <p><a href='mailto:info@hiddentproducts.com'>will@hiddentproducts.com</a></p>
          </div>
        </div>
       
      </div>
    </div>


 </div>
 
 <Footer />
</WhiteBodyTemplate>
}

export default ContactUsPage