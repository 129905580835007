import React, { useEffect } from "react";
import IGalleryImage from "../../../models/GalleryImage";
import MuxPlayer from "@mux/mux-player-react";

interface GalleryImageProps {
    image: IGalleryImage;
    onClick: (image: IGalleryImage) => void;
}

function GalleryImage(props: GalleryImageProps) {

  const isVideo = props.image.type === "video";
  const isProcessed = props.image.processed;
  // useEffect(() => {
  //   if(isVideo && !isProcessed) {
  //     let timer = setInterval(() => {
  //       console.log(props.image.id, "Checking for video processing status...");
  //     }, 5000);
  //     return () => {
  //       clearInterval(timer);
  //     };
  //   }
  // }, []);

    if (props.image.comment) {
        console.log("Comment: " + props.image.comment);
    }
    return (
        <div className="gallery-item">
            {props.image.type === "image" && (
                <>
                    <div
                        className="gallery-image"
                        style={{
                            backgroundImage: `url(/images/gallery-loading.gif)`,
                        }}
                        onClick={() => props.onClick(props.image)}
                    >
                        <img
                            src={props.image.src.small}
                            alt={props.image.alt}
                            style={{
                                aspectRatio: props.image.aspectRatio,
                            }}
                        />
                    </div>
                </>
            )}
            {props.image.type === "video" && props.image.processed && (
                <>
                    <MuxPlayer
                        streamType="on-demand"
                        playbackId={props.image.playbackID!}
                        metadataVideoTitle="Placeholder (optional)"
                        metadataViewerUserId="Placeholder (optional)"
                        primaryColor="#FFFFFF"
                        secondaryColor="#000000"
                        style={{
                            aspectRatio: props.image.aspectRatio,
                        }}
                        className="gallery-video"
                    />
                </>
            )}
            {props.image.type === "video" && !props.image.processed && (
                <>
                    <div className="processing-video-wrap">
                      Video is processing, please check back later.
                    </div>
                </>
            )}
            <div className="gallery-comment">{props.image.comment}</div>
        </div>
    );
}

export default GalleryImage;
