import React, { useEffect } from 'react'
import './GalleryModal.scss'
interface GalleryModalProps {
    children?: any;
    image?: string;
    alt?: string;
    onClose: () => void;
}
function GalleryModal(props: GalleryModalProps) {

    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        const onImageLoad = () => {
            setLoaded(true);
           // console.log("LOADED");
        };

        const image = new Image();
        image.src = props.image || '';
        image.addEventListener('load', onImageLoad);

        return () => {
            image.removeEventListener('load', onImageLoad);
        };
    }, []);

    const handleOnClose = () =>
    {
        props.onClose();
    }


  return (
    <div className='gallery-modal-wrap'>
        <div className='gallery-modal'>
            <div className='gallery-modal-close' onClick={handleOnClose}>X</div>
            {!loaded ? <div className='loading'><img src='/images/gallery-loading.gif' /></div> : <img src={props.image} /> }
        </div>
    </div>
  )
}

export default GalleryModal