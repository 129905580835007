import React from 'react'
import './PageBanner.scss'

function PageBanner(props: {
    children: any,
    className?: string,
    background?: string
}) {
  return (
    <div className={`page-banner ${props.className}`} style={{
        backgroundImage: `url(${props.background})`
    }}>
        {props.children}
    </div>
  )
}

export default PageBanner