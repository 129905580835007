import React from 'react'
import Header from '../organisms/header/Header'

function ReviewsPage() {
  return (
    <>
        <Header currentPage={'reviews'} isHome={false} />
   </>
  )
}

export default ReviewsPage