import React, { useEffect, useRef, useState } from 'react'
import './GalleryPage.scss'
import Header from '../organisms/header/Header'
import WhiteBodyTemplate from '../templates/whitebody-template/WhiteBodyTemplate'
import PageBanner from '../molecules/page-banner/PageBanner'
import Footer from '../organisms/footer/Footer'
import GalleryModal from '../molecules/gallery-modal/GalleryModal'
import IGalleryImage from '../../models/GalleryImage'
import GalleryImage from '../molecules/gallery-image/GalleryImage'
import MobileHeader from '../organisms/header/MobileHeader'
import WavyText from '../molecules/wavy-text/WavyText'
import { Icons } from '../atoms/Icons'
import UploadVideo from '../molecules/upload-video/UploadVideo'


const apiUrls = {
  local: 'http://172.20.68.5:80/hiddent/favoriteCMS/api/get_gallery.php?local=true',
  live: 'https://favoritecreative.com/hiddent/favoriteCMS/api/get_gallery.php',
};

const videoAPIUrl = {
  local: 'http://172.20.68.5:80/hiddent/favoriteCMS/api/create_video_upload_url.php',
  live: 'https://favoritecreative.com/hiddent/favoriteCMS/api/create_video_upload_url.php'
}

const galleryUploadApiUrls = {
  local: 'http://172.20.68.5:80/hiddent/favoriteCMS/api/upload_gallery.php?local=true',
  live: 'https://favoritecreative.com/hiddent/favoriteCMS/api/upload_gallery.php'
};

const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
const acceptedVideoFileTypes = [
  'video/mp4', 'video/avi', 'video/mpeg', 'video/quicktime', 'video/x-ms-wmv', 
  'video/x-flv', 'video/x-matroska', 'video/webm', 'video/3gpp', 'video/3gpp2', 
  'video/ogg', 'video/x-msvideo', 'video/x-m4v', 'video/x-f4v', 'video/x-m4v', 
  'video/x-ms-asf', 'video/x-mp4v-es', 'video/h264', 'video/h265'
];

const wavyWords = [
  "Reeling in your catch...", "Casting for your photo/video...", 
  "Hook, line, and sinker – almost there!", "Baiting for the big reveal...", 
  "Fishing for your upload...", "Netting your upload...", 
  "Wading through the data stream...", "Spooling the line, hang tight...", 
  "Finishing up..."
];

const GalleryPage = () => {
  const [images, setImages] = useState<IGalleryImage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<IGalleryImage | null>(null);
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);
  const [uploadingImageText, setUploadingImageText] = useState<string>(wavyWords[0]);
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const [comment, setComment] = useState<string>('');
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [uploadedType, setUploadedType] = useState<string>('image');
  const apiURL = apiUrls.live;
  const galleryUploadURL = galleryUploadApiUrls.live;
  const videoAPIURL = videoAPIUrl.live;

  useEffect(() => {
    getImages();
  }, []);

  useEffect(() => {
    if (uploadingImage) {
      const interval = setInterval(() => {
        setUploadingImageText(prevText => {
          const nextIndex = wavyWords.indexOf(prevText) + 1;
          return wavyWords[nextIndex] || wavyWords[wavyWords.length - 1];
        });
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [uploadingImage]);

  const getImages = async () => {
    try {
      const response = await fetch(apiURL);
      const data = await response.json();

      const newArr = data.images.map((image: any) => ({
        id: image.id,
        src: {
          small: image.src.small,
          full: image.src.large,
        },
        alt: "Image 3",
        aspectRatio: image.aspectRatio,
        comment: image.comment,
        type: image.type,
        duration: image.duration,
        playbackID: image.playbackID,
        processed: true
      }));

      setImages(newArr);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnClose = () => setExpanded(false);

  const handleSelectImage = (image: IGalleryImage) => {
    setSelectedImage(image);
    setExpanded(true);
  };

  const handleUploadButtonClicked = () => fileUploadRef.current?.click();

  const handleFileInputChange = () => {
    const file = fileUploadRef.current?.files?.[0];
    if (file && isFileInputValid(file)) {
      // get either video or image 
      const type = file.type.split('/')[0];
      setUploadedType(type);
      generateThumbnail(file);
    } else {
      alert('Please select a valid photo/video file. (jpeg, png, gif, avi, mov, mp4, etc.)');
    }
  };

  const isFileInputValid = (file: File) => (
    acceptedFileTypes.includes(file.type) || acceptedVideoFileTypes.includes(file.type)
  );

  const generateThumbnail = (file: File) => {
    if (!file) return;

    const createImageThumbnail = (imageSrc: string) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const maxSize = 300; // Desired thumbnail size
        let { width, height } = image;

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(image, 0, 0, width, height);
          setThumbnail(canvas.toDataURL('image/png'));
        }
      };
      image.src = imageSrc;
    };

    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event.target?.result as string;
        if (result) {
          createImageThumbnail(result);
        }
      };
      reader.readAsDataURL(file);
    } else if (file.type.startsWith('video/')) {
      const videoUrl = URL.createObjectURL(file);
      // TODO: create video thumbnail logic
      setThumbnail("video");
    }
  };

  const handleFormSubmit = () => {
    const endpoint = galleryUploadURL;
    const formData = new FormData();
    const file = fileUploadRef.current?.files?.[0];
    setUploadingImage(true);

 if (file) {
    if(uploadedType === 'video')
    {
      handleVideoChosen(file!);
      return;
    }

   
      formData.append('file', file);
      formData.append('comment', comment);

      fetch(endpoint, {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            const newImage = {
              id: data.image.id,
              src: {
                small: data.image.src.small,
                full: data.image.src.large,
              },
              alt: "Image 3",
              aspectRatio: data.image.aspectRatio,
              comment: comment,
              type: data.image.type,
              duration: data.image.duration,
              playbackID: data.image.playbackID,
              processed: true
            };
            setImages([newImage, ...images]);
            resetForm();
          } else {
            alert('There was an error uploading the image. Please try again.');
            setUploadingImage(false);
          }
        })
        .catch(err => {
          console.error(err);
          alert('There was an error uploading the image. Please try again.');
          setUploadingImage(false);
        });
    }
  };


  const [uploadLink, setUploadLink] = React.useState('');

  /** VIDEO SPECIFIC **/
  const getUploadLink = async () => {
    setUploadingImage(true);
    const response = await fetch(videoAPIURL, {
      method: 'POST',
      body: JSON.stringify({comment: comment}),
    });
    const data = await response.json();
    setUploadLink(data.url);
    return data.url;
  };

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };


const handleVideoChosen = async (file: File) => {
  
  if (file) {
    const link = await getUploadLink();
    console.log('link', link);
    if (!link) {
      alert('Please get upload link first');
      return;
    }

      const uploadId = generateUUID();
      //setUploadingVideoState(UploadStatus.UPLOADING);
      let formData = new FormData();
      formData.append('file', file);
      formData.append('uploadId', uploadId);

      fetch(link, {
          method: 'PUT',
          body: file,
          headers: {
              'Content-Type': file.type,
          }
      }).then(response => {
          if (response.ok) {
              //setUploadingVideoState(UploadStatus.UPLOADED);
              setUploadingImage(false);
              const newImage : IGalleryImage = {
                id: uploadId,
                src: {
                  small: '',
                  full: '',
                },
                alt: "Image 3",
                aspectRatio: '16:9',
                comment: comment,
                type: 'video',
                duration: '0',
                uploadId: uploadId,
                processed: false,
              };
              setImages([newImage, ...images]);
          } else {
              response.text().then(text => {
                  console.error('Upload failed:', text);
                  //setUploadingVideoState(UploadStatus.FAILED);
                  
                  
              });
          }
      }).catch(error => {
          console.error('Upload error:', error);
          //setUploadingVideoState(UploadStatus.FAILED);
          setUploadingImage(false);
      }).finally(() => {
        setThumbnail(null);
        setUploadingImage(false);
      });
  }
}

  const resetForm = () => {
    setComment('');
    setThumbnail(null);
    if (fileUploadRef.current) {
      fileUploadRef.current.value = '';
    }
    setUploadingImage(false);
  };

  const handleFakeGalleryItem = () => {
    const newImage : IGalleryImage = {
      id: '123',
      src: {
        small: '',
        full: '',
      },
      alt: "Image 3",
      aspectRatio: '16:9',
      comment: comment,
      type: 'video',
      duration: '0',
      uploadId: '123',
      processed: false,
    };
    setImages([newImage, ...images]);
  };

  const handleDiscardEvent = () => {
    setThumbnail(null);
    if(fileUploadRef.current) {
      fileUploadRef.current.value = '';
    }
  };
  return <div className='gallery-page-wrap'><WhiteBodyTemplate>

    <Header currentPage={'gallery'} isHome={false} />
    <MobileHeader currentPage='gallery' isHome={false} />
    <PageBanner background='/images/gallery-banner.jpg' className='gallery-banner-wrap'><h1> The <b>Gallery</b></h1></PageBanner>

    {expanded && <GalleryModal image={selectedImage?.src.full} onClose={handleOnClose} />}

    <div className='upload-own-wrap-container'>

{/* <button onClick={handleFakeGalleryItem}>Fake Gallery Item</button> */}

  {uploadingImage && <>
      <div className='upload-loading-container'>
        <div className='upload-loading'>
          <div className='loading-icon'><Icons.Boat /></div>
          <h1>{uploadingImageText}</h1>
          <h3>We're uploading your photo, please wait a moment.</h3>
        </div>
      </div></>}

      {!uploadingImage && <><div className='upload-own-wrap'>
      <h2>Upload your own photos and videos!</h2>
      <h3>Post your HIDDEN T catches.</h3>
      {!thumbnail && <div className='upload-own-button' onClick={handleUploadButtonClicked}>UPLOAD PHOTOS & VIDEOS</div>}
      

{/* <UploadVideo /> */}
      
      
      <div className='hidden-input-wrap'>
        <input type='file' name='file' id='file' ref={fileUploadRef} onChange={handleFileInputChange} />
      </div>
      {thumbnail && <><div className="preview-post">
        <div className='preview-left'>
          {thumbnail && thumbnail != "video" && <img src={thumbnail} />}
          {thumbnail && thumbnail == "video" && <div className='empty-video-src'>video</div>}
          {thumbnail && thumbnail && <div className='discard-button' onClick={handleDiscardEvent}>Discard</div>}
          </div>
        <div className='preview-right'>
          <div className='preview-heading'>Comment</div>
          <div className='preview-textarea-wrap'>
  <textarea value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Write a comment...'></textarea>
</div>
          <div className='final-submit-button-wrap'>
            <div className='final-submit-button' onClick={handleFormSubmit}>Submit</div>
          </div>
        </div>
      </div></>}
    </div></>}
    </div>

    {loading && <div className='api-loading'>Getting Images...</div>}
    <div className='gallery-wrap'>
      {
        images.map((image, index) => {
          return <GalleryImage image={image} key={index} onClick={() => handleSelectImage(image)} />
        })
      }
    </div>



  </WhiteBodyTemplate>
    <Footer />
  </div>
}

export default GalleryPage