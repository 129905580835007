import React from "react";
import "./KomfortStikPage.scss";
import WhiteBodyTemplate from "../templates/whitebody-template/WhiteBodyTemplate";
import Header from "../organisms/header/Header";
import MobileHeader from "../organisms/header/MobileHeader";
import { useParams } from "react-router";
import PageBanner from "../molecules/page-banner/PageBanner";
import Footer from "../organisms/footer/Footer";

let komfort_data: KomfortStikData[] = [
    {
        queryID: "3500",
        model: "Komfort Stik Model 3500",
        desc: 'Conventional Rod, Offshore / Big Cat or Grouper, 6\'6", 30/50 lbs. EGlass, Hidden T Component, open Right side. HD Hook-Keeper, Spiral Wrap, Virtus 130 lb. Unlimited, Double Foot Eyes, RED abalone shrink wrap, RED METALLIC THREAD THROUGHOUT. "Komfort Stik" letters in Bright Silver, standard HIDDEN T LOGO. Under Label: 6\'6" HT Model 3500 -30-50lb -Med/Hvy – X Fast. Carbon Fiber, EGlass.',
        image: "/images/komfort-stik-3500.jpg",
    },
    {
        queryID: "2500",
        model: "Komfort Stik Model 2500",
        desc: 'Spinning Rod, Mid-Range: Salmon/Snapper/Catfish Rod, 6\'6" 30/50 lbs. Hidden T component, open to the right or left side. HD Hook Keeper, Double foot Ti-Forged guides, Metallic Emerald Green abalone shrink wrap, EMERALD GREEN METALLIC THREAD THROUGHOUT. "Komfort Stik" letters in Bright Silver, standard HIDDEN T LOGO. Inder Label: 6\'6" HT Model 2500 -30-50lb -Med/Hvy – Fast. Carbon Fiber, EGlass.',
        image: "/images/komfort-stik-2500.jpg",
    },
    {
        queryID: "1500",
        model: "Komfort Stik Model 1500",
        desc: "Spinning Rod, Light-Range Bass/Trout/ Snook/Redfish, 7' 10/20 lbs. Hidden T component, open to the right or left side. Hook Keeper, Double foot Ti-Forged guides, Metallic Royal BLUE abalone shrink wrap, ROYAL BLUE BLUE METALLIC THREAD THROUGHOUT. \"Komfort Stik\" letters in Bright Silver, standard HIDDEN T LOGO. Under Label: 7' HT Model 1500 -10-20lb -Med – Fast. Carbon Fiber, EGlass",
        image: "/images/komfort-stik-1500.jpg",
    },
];

interface KomfortStikData {
    queryID: string;
    model: string;
    desc: string;
    image: string;
}

function KomfortStikPage() {
    return (
        <WhiteBodyTemplate>
            <Header currentPage={"komfort-stik"} isHome={false} />
            <MobileHeader currentPage="komfort-stik" isHome={false} />
            <PageBanner
                background="/images/komfort-stik-banner.jpg?cache=04-04-2024"
                className="komfort-stick-banner-wrap"
            >
                &nbsp;
            </PageBanner>
            <div className="kimfort-stik-content-wrap">
                <div id="komfort-stik-overall-wrap">
                    <div id="komfort-stik-introducing">
                        <h3>Introducing the all new</h3>
                        <h1>
                            <img src="/images/komfort-stik-logo.png" />
                        </h1>
                        <div className="komfort-desc">
                            Experience the pinnacle of fishing technology with
                            the Komfort Stik by Hidden T. Crafted with precision
                            and designed for performance, the Komfort Stik
                            offers unparalleled Komfort and control. Whether
                            you're tackling big Offshore catches or enjoying a
                            leisurely day at the lake, from the 1500 to the
                            3500, any Komfort Stick model will become your
                            ultimate fishing companion. With the standard Hidden
                            T Comfort Component coupled with other features like
                            HD Hook-Keeper, double foot eyes, and abalone shrink
                            wrap, the Komfort Stik ensures that every cast and catch
                            is smooth and enjoyable. Upgrade your fishing game.
                            Choose a Komfort Stik model that suits your needs
                            and feel the difference with every retrieve.
                        </div>
                    </div>

                    <div className="made-in-america">
                        <img src="/images/komfort-stik-made-in-america.jpg" />
                    </div>

                    {komfort_data.reverse().map((data, index) => {
                        return (
                            <div className="komfort-stik-indi">
                                <div className="komfort-title">
                                    {data.model}
                                </div>
                                <div className="komfort-images">
                                    <img src={data.image} />
                                </div>
                                <div className="komfort-desc">{data.desc}</div>
                            </div>
                        );
                    })}

                    <div className="coming-soon-rid">
                        <div className="komfort-stik-indi">
                            <div className="komfort-title">
                                TARPON STIK - Coming Soon
                            </div>
                            <div className="komfort-desc">
                                Spinning Rod, TARPON STIK Mid to Hvy-Range, 7'
                                6" 50/80 lbs. Hidden T component, open to the
                                right or left side. Hook Keeper, Double foot
                                Ti-Forged guides, Metallic Silver abalone shrink
                                wrap, Royal SILVER METALLIC THREAD THROUGHOUT.
                                “Komfort Stik” letters in Bright Silver,
                                standard HIDDEN T LOGO.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </WhiteBodyTemplate>
    );
}

export default KomfortStikPage;
