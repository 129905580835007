import React from 'react'

function TxtLargeLogo() {
  return (
    <div className='slide1-full '>
      <div className='text-top'>
<span className='bigtext'>The Ultimate Solution</span>
      </div>
        <div className='large-logo'>
            <img src='/images/logo.png' />
        </div>
      <div className='text-bottom'>
      <span className='bigtext'>To an age old problem!</span>
      </div>
    </div>
  )
}

export default TxtLargeLogo