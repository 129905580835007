import React, { useContext } from 'react'
import './MobileHeader.scss'
import { ConfigContext } from '../../../contexts/ConfigContext';
import { useNavigate } from 'react-router';

function MobileHeader(props: {
    isHome: boolean,
    currentPage: string,
}){
    const ctx = useContext(ConfigContext);
    const navigate = useNavigate();

    const [tapped, setTapped] = React.useState<boolean>(false);

    const closeMenu = () => {
        setTapped(false);
    }
    const openMenu = () => {
        setTapped(true);
    }
    const getCurrentPage = () => {
        for(let i = 0; i < ctx.headerItems.length; i++){
            if(ctx.headerItems[i].identifier == props.currentPage){
                return ctx.headerItems[i];
            }
        }
    }
    const external_navigate = (link: string) => {
        window.open(link, '_self');
    }
    return (
        <>
        <div className='mobile-header'>
            <div className='mobile-logo' onClick={() => navigate("/")}>
                <img src='/images/logo.png' alt='HiddenT logo' />
            </div>
            <div className='mobile-menu' onClick={openMenu}>
                <div className='active'><span>{getCurrentPage()?.name}</span><img src='/images/hamburger.png' /></div>
            </div>

            <div className='large-mobile-menu' style={{
                display: tapped ? 'block' : 'none'
            }}>
                <div className='close' onClick={closeMenu}>[Close]</div>
                <div className='items'>
                    <nav>
                <ul>
                    {
                        ctx.headerItems.map((item, index) => {
                            if(item.external)
                            {
                                return <li
                            onClick={() => external_navigate(item.link)}
                            key={index} className={item.identifier == props.currentPage ? 'active' : ''}><span>{item.name}</span></li>
                            }
                            return <li
                            onClick={() => navigate(item.link)}
                            key={index} className={item.identifier == props.currentPage ? 'active' : ''}><span>{item.name}</span></li>
                        })
                    }
                </ul>
            </nav>
                </div>
            </div>
         
        </div>
        {props.currentPage.toLowerCase() != "komfort-stik" && <>
            <div className='komfort-stik-bar-header mobile-komfort-stik-header'>
            <div className='upsercase'> {props.currentPage} - Click Bar Below to View Our Elite Komfort Stik Rod Series</div>
                <img src='/images/komfort-stik-bar.jpg' onClick={() => navigate("/komfort-stik")} />
            </div>
            </>}
        </>
    )
}

export default MobileHeader