import React, { useEffect } from 'react'
import { useSpring, a } from '@react-spring/three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import AnimationSlide, { CustomVector3 } from '../../../models/AnimationSlide';
import { useAnimations } from '@react-three/drei';

interface Props {
    modelRef: any;
    animation: AnimationSlide;
    tempRotation?: CustomVector3;
    tempPosition?: CustomVector3;
    tempScale?: number;
    setObject: (object: THREE.Group) => void;
    object?: THREE.Group;
    rotationSpring: any,
    positionSpring: any,
    scaleSpring: any,
}
//const animationName = 'Base|Take 001|BaseLayer';
const animationName = 'BaseAction';
function Hero3DModel(props: Props) {
    const gltf = useLoader(GLTFLoader, '/models/hiddent2.glb');


    useEffect(() => {
        if (props.modelRef.current) {
            //console.log("setting"); 
          props.setObject(props.modelRef.current);
        }
      }, [props.modelRef.current]);

      const {animations} = gltf;

      const { actions } = useAnimations(animations, gltf.scene);

  useEffect(() => {
    //console.log("playAnimation: ", props.animation.modelData.hasAnimation)
    //console.log("slide: ", props.animation)
    if(actions[animationName])
    {
        const action = actions[animationName];
        if(props.animation.modelData.hasAnimation)
        {
            //console.log("playing animation")
            action.loop = THREE.LoopOnce; // loop only once
            if(props.animation.animationDuration)
            {
              action.setDuration(props.animation.animationDuration / 1000);
            }
            
            action.clampWhenFinished = true; // stop at the last frame
            action.play();
        }
        else
        {
            
            action?.stop();
            //console.log("resetting animation");
        }
    }
    //console.log("end");
  }, [props.animation.modelData.hasAnimation]);
  

      return (
        <a.group ref={props.modelRef} position={props.positionSpring as any} rotation={props.rotationSpring as any} scale={props.scaleSpring}>
        <primitive object={gltf.scene} />
      </a.group>
      );
}

export default Hero3DModel