import React from 'react'
import './InstallationPage.scss';
import WhiteBodyTemplate from '../templates/whitebody-template/WhiteBodyTemplate';
import Header from '../organisms/header/Header';
import PageBanner from '../molecules/page-banner/PageBanner';
import Footer from '../organisms/footer/Footer';
import MobileHeader from '../organisms/header/MobileHeader';

function InstallationPage() {
    return <WhiteBodyTemplate>

    <Header currentPage={'installation'} isHome={false} />
    <MobileHeader currentPage='installation' isHome={false} />
<PageBanner background='images/installation-banner.jpg?cache=04-04-2024' className='about-banner-wrap'><h1>HOW TO <b>INSTALL</b></h1></PageBanner>
  

   <div className='installation-content-wrap'>
   <div className='install-video-details'>
            <h4>Please note:</h4>
            <h2>The HIDDEN T Installation Video information has changed.</h2>
            <h1>HIDDEN T COMPONENT will accept up to 7/8" O.D. Rod Blanks.</h1>
            <h3>Component Dimensions are as follows:</h3>
            <ul>
            <li>Length: 8.5"</li>
            <li>OD: 1.25"</li>
            <li>Blank Insert Hole ID: 7/8"</li>
            <li>1/2" Blank, 4" Depth</li>
            <li>5/8" Blank 3" Depth</li>
            </ul>
        </div>
    <div className='install-video-wrap'>
       
    <div>
        <iframe frameBorder={0} src="https://player.vimeo.com/video/954485481?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen  title="354448755_490866329893856_5057950958483353319_n"></iframe>
        </div>
    <script src="https://player.vimeo.com/api/player.js"></script>
    <div className='installation-image'><img src='images/installation.jpg?cache=04-04-2024' /></div>
    </div>

    <div className='large-installation-image'>
        <img src='images/rod-install.jpg?cache=04-04-2024' />
    </div>

    

   </div>
   
   <Footer />
</WhiteBodyTemplate>
}

export default InstallationPage