import React, {useEffect, useState} from 'react'
import './AboutUsPage.scss'
import Header from '../organisms/header/Header'
import WhiteBodyTemplate from '../templates/whitebody-template/WhiteBodyTemplate'
import AboutUsBio from '../molecules/about-us/AboutUsBio'
import AboutBio from '../../models/AboutBio'
import Footer from '../organisms/footer/Footer'
import PageBanner from '../molecules/page-banner/PageBanner'
import MobileHeader from '../organisms/header/MobileHeader'

const bios : AboutBio[] = [
    {
        image: 'images/greg.jpg',
        name: 'Mr. Gregory Steiner of Cape Coral, Fl',
        tagline: '',
        bioStart: <><p>As one of the owner/managers of HIDDEN T PRODUCTS LLC, Mr. Steiner brings his experience in manufacturing, wholesale, and retail sales into the development of this product.</p></>,
        bioRest: <><p>Over the past three decades, Steiner has produced many beneficial items to be utilized by people around the globe.</p>
        <p>One worthy of note is the then, ONE-OF-A-KIND, “Squeeze Breeze”, a portable battery-operated water misting fan, which he conceived, patented, and developed. It has now been enjoyed by millions worldwide to cool off on a hot summer day.
        Formally owned O2Cool Company and are still carried by most major retailers from Walmart to Target. Major amusement parks too, such as Disneyland and Disney World, in the beginning, over unto Universal Studios’, selling several million units while he owned the company.</p>
        <p>Many of the products he developed including “Squeeze Breeze” are still sold in stores today and can be found at Home Depot, Lowe’s, Walgreens, CVS and many more retail outlets. It all began over thirty years ago, proving the products’ longevity. Over 150 million “Squeeze Breezes” have now been sold to date.</p>
        <p>Mr. Steiner is now Co-developer of the Hidden T Fishing Rod Component along with Inventor of the HIDDEN T component, Captain Russ Walker, also of Cape Coral, FL.
        Once again expectations are high for this all new “ONE-OF-A-KIND”, rod building product to become an integral “must have” component to be found on every fishing rod.
        From his experience, Mr. Steiner believes this new product may even exceed his earlier successes to be utilized by vast numbers of sportsmen and the fishing communities around the world, for many years to come.</p></>
    },
    {
        image: 'images/will.jpg',
        name: 'Mr. Will Smith',
        tagline: '',
        bioStart: <><p>Mr. Will Smith, owner/manager HIDDEN T Products LLC. Mr. Smith traveled to Lee County in 1980 where sunny days, magnificent waterways and the islands made their mark upon him. He never left. </p></>,
        bioRest: <><p>The flowing years presented some of the opportunities that make this State great. When capitalizing upon the real estate market, timing is everything. Will Smith wasted no time taking advantage of it.</p><p>Will Smith subsequently became a part of the community. The foundation of his expertise lies in construction. He has built many upper end homes for clients, mainly on the islands of Sanibel and Captiva.</p><p>As previously stated, “Timing is Everything”. Like the aforementioned opportunities, Will Smith recognized the one-of-a-kind, HIDDEN T COMPONENT for the opportunity that it is and decided to join the team to help complete its development and create a marketing plan. </p></>
    },
    {
        image: 'images/russ.jpg',
        name: 'Mr. Russell “Captain Russ” Walker',
        tagline: 'Inventor/Owner/Manager HIDDEN T PRODUCTS LLC',
        bioStart: <><p>Professional SWFL Fishing Guide and Charter Captain 2011 - Current (Salt Water 47 Years)<br />Retired 32 years as Petroleum Geologist<br />
        Founder of Investors Resources Corporation, Enid, Ok.1981<br />
        Owner of Champion Drilling Company 1976<br />
        Walker Oilfield Welding Company, solving welding and mechanical problems for the Oil and Gas Industry since 1972.
        </p></>,
        bioRest: <><p>Owner of Patent # 4,962,609, Oct 16, 1990 - Fish Attractant Fishing Lure<br />
        Owner of Patent # 10,858,074 BI, Dec 8, 2020 - Radial Arm Self Releasing Danforth, fluke style boat Anchor, KNOWN AS THE ECOANCHOR
        </p>
        <p>Inventor of the HIDDEN T Pressure Dispersing Comfort Gimbal Fishing Rod Handle Component Pat Pend. #63/473,125 May 4, 2022<br />
U.S. Patent Application<br />
For "Pressure Dispersing Apparatus and Method of Using Same for Fishing Rod"<br />
Serial Number 18/142,778<br />
Dated May 3, 2023<br />
Official Filing Receipt from the U.S. Patent and Trademark Office for the above referenced U.S. Patent App.
</p>
        <p>International Patent Co-Operation Treaty Filed May 3, 2023 HIDDEN T Trade Mark
</p>
        <p>2013 to Present: Captain Russ Walker, now in his second career as a Masters Licensed, Professional Florida Fishing Guide.<br />
Walker has 47 years of Saltwater fishing experience. He’s covered the Texas, Louisiana, the Canadian and Florida coasts including Sanibel, Captiva, North Captiva Islands, and the Florida Keys.<br />
Captain Russ specializes in Tarpon, Shark, Pompano, Trout, Mackerel, Cobia and Triple Tail inter-Coastal Fishing. 
</p>
<p>Beginners, Children and Family are always welcome. You don't have to be a Pro to have fun!</p>
<p><a href='https://www.tidewalkercharters.com' target='_blank'>www.tidewalkercharters.com </a></p>
</>
    },
    {
        image: 'images/hiddent-about.jpg?cache=04-04-2024',
        name: 'Introducing the HIDDENT T Component',
        tagline: 'HOW THE HIDDEN T CAME ABOUT',
        bioStart: <><p>The Hidden T invention, the way it's shaped and operates came to me through trial and error over a period of many years of fishing as a professional guide. I specifically remember one day a lady client onboard showed me a bruise on her abdomen, after a big day on the water, from the rod butt poking her while she was reeling in her seemingly endless Sea Trout and Mackerel catch.</p></>,

        bioRest: <><p>The rubber rod butt device I had on board, had obviously blown out of the boat during transit. So, I had nothing to offer her short of a rolled-up bar towel to ease the pain of the handle end of the rod against her body.</p>
        <p>That's when it all started. Probably more like three or four years ago. 
        The Rolled-up bar towel is when I first saw the cross shape that would ultimately become the Hidden T.</p>
        <p>I kept thinking there's got to be something better than all this … the gimbal belts that are always stored and are all so cumbersome. For lack of a better solution, I went ahead and bought another rubber stopper to have on board. As before, not only could it hard to initially get it on the rod end, needing water or spit lube, it sometimes required two people pulling opposite each other to remove it. What a hassle.</p>
        <p>After each use the rod and reel would not go back in the gunnel rod holder without removing the rubber pad. After about 6 times…What a process! I was thinking.
        In another instance, The Rubber cushion, with use, became soiled over time, holding a dirty substance, perhaps from suntan oil or just plain use. I don’t remember. My client refused to use it thinking it would ruin his new $70 Florida fishing shirt. I can't blame him.</p>
        <p>In another instance, the aged cushion actually smudged the color it was made of, onto his shirt. Never to be the same again.</p>
        <p>Daily, I kept thinking about it, then I started putting it on paper. My drawings were many, over sixfold evolutionary as It started to become clear to me, once I began to zero in on what I needed to overcome the problem.</p>
        <p>I ended up with the quick snap T shape handle, which was always plastic clean, usable and instantly rod rack-able.</p>
        <p>I first called it the “SNAPPER”. Because of the locking mechanism which I designed to hold it vertically in place which “Snapped” into position with a clicking noise. 
        Over the next year, Greg and I developed it thru the 3-D printing process to the stage it is now ready for the molding process. The molds are now made and here we go.
        The name “HIDDEN T was later given to me during a morning nap. Seriously! I woke up and there it was, lodged in the corner of my mind. A descriptive phrase of what I saw it to be…a HIDDEN T.</p>
        <p>Do fisherman need it? Yes. Is it beneficial for all that will use it. Yes.
        Will it be received 100% by both pleasure and commercial fishermen, it should be. The device will move on the abdomen regardless of where the fight takes you. 
        Spongy Client bellies allow for easy pivoting. I've used it many times offshore on big grouper and it's awesome.</p>
        <p>It has a gimbal cross in the foot of the crossbar T so naturally, it works for trolling too. Most likely the super big game two hundred pound plus category fishermen will stay with the equipment that they currently know and use or perhaps take a ballpein hammer to it, trying to find a weakness. But luckily, those guys are the minority.</p>

        <p>The Majority, Every Day, Bass Pro, Internet Dream Shopper Sportsman, or Weekend Fisherman absolutely need it, plus it looks cool and is super functional.<br />
This is the first, truly new, fishing rod building component to come along in over 60 years. Simply stated; it’s a Must Have!<br />
Once you own it, just like the hook at the end of the line, it becomes a logical, integral component of your necessary fishing equipment inventory.</p>

<p>Never Lose it. When you need it, use it.<br />
Snap to Fight. Snap to Store.<br />
Hidden T gives you so much more.<br />
So, before you own it,<br />
Demand the right component,<br />
Ask for HIDDEN T.</p>

<p>The HIDDEN T, invented by Master’s License Captain Russ Walker.<br />
Developed by Greg Steiner and Captain Russ Walker.<br />
Thanks for taking the time to read this article.</p>

<p>U.S. & International Patent Pending, P.C.T<br />
Owned by HIDDEN T PRODUCTS LLC<br />
Cape Coral, Florida<br />
Captain Russ Walker<br />
USCG Masters License Guide</p>

<p><a href='www.tidewalkercharters.com' target='_blank'>www.tidewalkercharters.com</a><br />
239 994 7818
</p>

        
        
        </>
    },
   
]

export default function AboutUsPage()
{
    return <WhiteBodyTemplate>
         <Header currentPage={'about-us'} isHome={false} />
         <MobileHeader currentPage='about-us' isHome={false} />
    <PageBanner background='images/about-banner.jpg?cache=04-04-2024' className='about-banner-wrap'><h1>Our <b>Story</b></h1></PageBanner>
       

        <div className='about-content-wrap'>

            {bios.map((bio, index) => {
                return <AboutUsBio bio={bio} key={index} />
            })}

        </div>
        
        <Footer />
    </WhiteBodyTemplate>
}