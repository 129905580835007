import React, { useContext, useEffect } from 'react'
import './Header.scss';
import { useNavigate } from 'react-router';
import { ConfigContext } from '../../../contexts/ConfigContext';

type Position = 'absolute' | 'relative' | 'fixed' | 'static' | 'sticky';
interface viewStateProps {
    position: Position,
    top: string,
    background: string,
}

let defaultViewState : viewStateProps = {
    position: 'relative',
    top: '-8vh',
    background: 'transparent',
}
let scrolledViewState : viewStateProps = {
    position: 'sticky',
    top: '0px',
    background: 'white'
}

function Header(props: {
    isHome: boolean,
    currentPage: string,
}) {

    const ctx = useContext(ConfigContext);
    const [headerViewState, setHeaderViewState] = React.useState<viewStateProps>(props.isHome ? defaultViewState : scrolledViewState);
    const headerRef = React.useRef<HTMLDivElement>(null);


    const navigate = useNavigate();

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY >= window.innerHeight) { // window.innerHeight is effectively 100vh
                setHeaderViewState(scrolledViewState);
            } else {
                setHeaderViewState(defaultViewState);
            }
        };

        if(props.isHome)
        {
            window.addEventListener('scroll', onScroll);
        }
        

        // Cleanup the listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);



    
    

  return (
    <div ref={headerRef} className='header-container' style={{
        // set the position
        position: headerViewState.position,
        top: headerViewState.top,
        backgroundColor: headerViewState.background,
    }}>
        <div className='header-left'>
            <div className='logo' onClick={() => navigate("/")}>
                <img src='/images/logo.png' />
            </div>
        </div>
        <div className='header-center'>
            
            {props.currentPage != "komfort-stik" && <>
            <div className='komfort-stik-bar-header'>
            <div className='upsercase'>Click Bar Below to View Our Elite Komfort Stik Rod Series</div>
                <img src='/images/komfort-stik-bar.jpg' onClick={() => navigate("/komfort-stik")} />
            </div>
            </>}
            <nav>
                <ul>
                    {
                        ctx.headerItems.map((item, index) => {
                            if(!item.onlyMobile)
                            {
                                return <li
                                onClick={() => navigate(item.link)}
                                key={index} className={item.identifier == props.currentPage ? 'active' : ''}>{item.name}</li>
                            }
                            
                        })
                    }
                </ul>
            </nav>
        </div>
        <div className='header-right'>
            <a href='https://favoritecreative.com/hiddent/favoriteCMS/api/store_redirect.php' target='_blank' className='buy-now-button'>Buy Now</a>
        </div>
    </div>
  )
}

export default Header