import React from 'react'

export const Icons = {
    Boat: () => (
        <svg height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
	 viewBox="0 0 512.001 512.001">
<g>
	<path style={{fill: "#7BA9D6" }} d="M234.67,373.429L50.9,383c-5.95,0.31-12.115,5.015-10.834,10.833l21.332,96.667
		c1.285,5.818,4.879,10.833,10.834,10.833H385.9c5.942,0,7.655-5.814,10.832-10.833l74.671-118.001
		c3.178-5.019-4.883-11.143-10.834-10.833l-183.229,9.542L234.67,373.429z"/>
	<path style={{fill: "#F9EACD" }} d="M234.67,166.785v163.922v42.723l42.669-2.222v-42.723V32c0-11.734-9.601-21.332-21.332-21.332
		c-11.736,0-21.337,9.599-21.337,21.332v55.916L234.67,166.785L234.67,166.785z"/>
	<g>
		<path style={{fill: "#9FDEE2" }} d="M277.339,32v296.484l148.769-7.748c16.261-0.846,24.058-17.886,14.547-32.771L277.339,32z"/>
		<path style={{fill: "#9FDEE2" }} d="M234.67,330.707V166.785v-78.87l-8.332,10.92C206.682,124.355,105.682,243.82,70.584,298.473
			c-12.613,19.64-5,41.037,12.147,40.145L234.67,330.707z"/>
	</g>
</g>
<g>
	<path style={{fill: "#000003" }} d="M480.741,362.365c-3.629-6.593-11.928-11.379-19.73-11.379c-0.335,0-0.669,0.009-0.996,0.026
		l-172.008,8.957v-21.36l138.655-7.221c11.072-0.576,20.578-6.55,25.429-15.979c5.24-10.183,4.326-22.59-2.442-33.183L287.825,28.6
		C286.122,12.548,272.504,0,256.007,0c-17.647,0-32.004,14.355-32.004,32v52.31l-6.114,8.016
		c-5.042,6.543-15.717,19.61-29.235,36.152c-37.696,46.131-100.785,123.339-127.045,164.23c-9.227,14.369-10.582,30.837-3.535,42.98
		c4.999,8.61,13.765,13.623,23.672,13.623c0.511,0,1.025-0.013,1.541-0.041l140.716-7.327v21.36l-173.658,9.044
		c-6.765,0.353-13.393,3.758-17.299,8.889c-3.342,4.386-4.549,9.675-3.396,14.896l21.332,96.67
		c2.577,11.663,10.917,19.199,21.249,19.199H385.9c11.006,0,15.784-8.595,18.351-13.211c0.518-0.936,1.01-1.82,1.496-2.585
		l74.671-118.001C483.445,373.417,483.565,367.497,480.741,362.365z M433.121,305.65c-1.398,2.715-4.015,4.248-7.57,4.433
		l-137.545,7.163V68.551l143.66,225.157C434.303,297.833,434.844,302.298,433.121,305.65z M82.176,327.965
		c-2.592,0.133-4.392-0.816-5.65-2.988c-2.431-4.188-2.435-12.225,3.034-20.74c25.601-39.866,88.207-116.482,125.613-162.26
		c7.215-8.828,13.614-16.66,18.83-23.103v44.284c-5.876,7.641-22.395,29.096-36.171,46.78c-3.622,4.648-2.787,11.35,1.858,14.97
		c1.948,1.518,4.256,2.253,6.549,2.253c3.178,0,6.319-1.412,8.423-4.112c6.3-8.089,13.172-16.96,19.341-24.946V320.58
		L82.176,327.965z M245.338,32c0-5.881,4.787-10.665,10.669-10.665S266.672,26.119,266.672,32v329.082l-21.335,1.112V32z
		 M387.719,484.794c-0.809,1.278-1.502,2.528-2.116,3.63c-0.373,0.673-0.868,1.563-1.288,2.241H72.761
		c-0.267-0.402-0.667-1.201-0.946-2.464l-20.847-94.467c0.158-0.043,0.325-0.074,0.487-0.082l407.361-21.214L387.719,484.794z"/>
	<path style={{fill: "#000003" }} d="M184.713,231.406c-4.565-3.721-11.284-3.037-15.007,1.53c-0.367,0.448-0.695,0.844-0.977,1.182
		c-3.775,4.524-3.167,11.251,1.356,15.025c1.996,1.665,4.419,2.477,6.829,2.477c3.052,0,6.086-1.306,8.197-3.834
		c0.325-0.391,0.708-0.85,1.132-1.371C189.964,241.847,189.28,235.128,184.713,231.406z"/>
	<path style={{fill: "#000003" }} d="M387.852,419.993c0.164,0,0.331-0.003,0.498-0.012l0.927-0.043
		c5.886-0.263,10.442-5.249,10.178-11.134s-5.257-10.442-11.134-10.179l-0.95,0.044c-5.884,0.27-10.436,5.26-10.165,11.145
		C377.468,415.534,382.185,419.993,387.852,419.993z"/>
	<path style={{fill: "#000003" }} d="M290.64,425.534c0.289,0,0.581-0.012,0.875-0.035c14.755-1.196,36.19-2.498,65.523-3.981
		c5.884-0.298,10.411-5.308,10.115-11.192c-0.298-5.885-5.338-10.412-11.192-10.115c-29.962,1.514-50.988,2.793-66.17,4.024
		c-5.873,0.476-10.246,5.622-9.771,11.494C280.473,421.307,285.14,425.534,290.64,425.534z"/>
</g>
</svg>
    )
}