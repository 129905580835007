import React from 'react';
import ReactDOM from 'react-dom/client';
import './startup/index.scss';
import { ConfigContextProvider } from './contexts/ConfigContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import AboutUsPage from './components/pages/AboutUsPage';
import GalleryPage from './components/pages/GalleryPage';
import ReviewsPage from './components/pages/ReviewsPage';
import ContactUsPage from './components/pages/ContactUsPage';
import ScrollToTopForNavigation from './helpers/ScrollToTopForNavigation';
import InstallationPage from './components/pages/InstallationPage';
import StoreNotReady from './components/pages/StoreNotReady';
import KomfortStikPage from './components/pages/KomfortStikPage';
import AdminPage from './components/pages/AdminPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ConfigContextProvider>
    <ScrollToTopForNavigation />
      <Routes>
        
        <Route path="/"  element={<>
            <HomePage />
        </>} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/reviews" element={<ReviewsPage />} />
        <Route path="/installation" element={<InstallationPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/store-not-ready" element={<StoreNotReady />} />
        <Route path="/komfort-stik" element={<KomfortStikPage />} />
        <Route path="/komfort-stik/:model" element={<KomfortStikPage />} />
      </Routes>
        
    </ConfigContextProvider>
  </BrowserRouter>
);
