import React, { useEffect, useState } from 'react'
import './Footer.scss'
import { ConfigContext } from '../../../contexts/ConfigContext';
import { Link } from 'react-router-dom';

interface hitPointResponse {
  success: boolean,
  results: number,
  video: number
}

function Footer() {

  const _ctx = React.useContext(ConfigContext);

  const [loading, setLoading] = React.useState(true);
  const [hits, setHits] = useState<number>(0);
  const [videoHits, setVideoHits] = useState<number>(0);


  const endpoints = {
    local: 'http://127.0.0.1/hiddent/favoriteCMS/api',
    live: 'https://favoritecreative.com/hiddent/favoriteCMS/api'
  }

  let endpoint = endpoints.live;



  const getHits = async () => {
    const response = await fetch(`${endpoint}/get_counter.php?userID=18`).then(res => res.json()).then(data => {
      data as hitPointResponse
      if(data.success)
      {
       setHits(data.results);
       setVideoHits(data.video);
      }
      else{
        console.log("bad");
      }
      setLoading(false);
    }).catch(err => console.log(err));
  };


  const logHit = async () => {
    if(!_ctx.hasUpdatedCounter)
    {
      const response = await fetch(`${endpoint}/update_counter.php?userID=18`);
      setHits(hits + 1);
      _ctx.updateCounter();
    }
  };

  useEffect(() => {
    getHits();
    logHit();
  }, []);

  const getYear = () => {
    return new Date().getFullYear();
  }
  return (
    <div className='footer-wrap' style={{
      
    }}>
    <div className='sizer'>
      <div className='footer'>
          <div className='footer-logo'>
              <img src='/images/logo.png' alt='HiddenT logo' />
          </div>
          <div className='footer-details'>
            <h2>HIDDEN T Products LLC</h2>
            <p>Cape Coral, Florida</p>
            <p><a href="tel:2399947818">(239) 994-7818</a></p>
            <p>hiddentproducts.com | Copyright {getYear()} &copy;</p> 
            <p>All Rights Reserved</p>
            <p>Website Designed / Developed by <a href='mailto:jonathanfavorite@hotmail.com'>FavoriteTechnologies LLC</a></p>
          
            {_ctx.adminLoggedIn && <>
          <div className='hit-counter-wrap'>
            <div className='hit-counter'>
              {loading ? "--" : hits.toString().replace(/(.)(?=(\d{3})+$)/g,'$1,')}
            </div>
            <div className='hit-desc'>
              website hits
            </div>
          </div>
            </>}
          {_ctx.adminLoggedIn && <>
          <div className='installation-hits'>
            <div className='installation-hits-desc'>Installation Video Hits</div>
            <div className='installation-hits-count'>{loading ? "--" : videoHits.toString().replace(/(.)(?=(\d{3})+$)/g,'$1,')}</div>
          </div>
          </>}

          {!_ctx.adminLoggedIn && <>
          <div className='admin-login-area'>
            <Link to='/admin'>Administrator Login</Link>
          </div>
          </>}
          <div className='legal'>
            HIDDEN T is a registered Trademark, property of Hidden T Products LLC. Cape Coral, Florida. All Slogans, descriptions and or otherwise artful presentations contained within the website are either registered and / or designated as copyrighted and are the sole property of Hidden T Products LLC. Unauthorized use of same is prohibited without the expressed written consent of Hidden T Products LLC.
          </div>
          </div>

      </div>
    </div>
  </div>

  )
}

export default Footer