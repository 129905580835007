import React from 'react'
import './AdminPage.scss'
import WhiteBodyTemplate from '../templates/whitebody-template/WhiteBodyTemplate'
import MobileHeader from '../organisms/header/MobileHeader'
import PageBanner from '../molecules/page-banner/PageBanner'
import Footer from '../organisms/footer/Footer'
import Header from '../organisms/header/Header'
import { ConfigContext } from '../../contexts/ConfigContext'

function AdminPage() {

    const _ctx = React.useContext(ConfigContext);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const real_username = 'admin';
    const real_password = 'walruss';

    const login = () => {
        if(username === real_username && password === real_password)
        {
            _ctx.adminLogin();
        }
        else{
            alert('Wrong username or password');
        }
    }

    return <WhiteBodyTemplate>
    <Header currentPage={'admin'} isHome={false} />
    <MobileHeader currentPage='admin' isHome={false} />

  


   <div className='admin-content-wrap'>
   {!_ctx.adminLoggedIn && <>
        <h1>Admin Login</h1>
        <div className='admin-login-form'>
            <input type='text' onChange={(e) => setUsername(e.target.value)} value={username} placeholder='Username' />
            <input type='password' onChange={(e) => setPassword(e.target.value)} value={password} placeholder='Password' />
            <button onClick={login}>Login</button>
        </div>
        </>}

        {_ctx.adminLoggedIn && <>
            <h1>Welcome back, Captain!</h1>
            <p>Only you can now see the installation video hits! Anything else that you might want to be able to view exclusively we can add here.</p>
        </>}
   </div>
   
   <Footer />
</WhiteBodyTemplate>
}

export default AdminPage